<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="/crm/user" :height="window.height - 310" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50"  @edit="openModalForEdit">
            <template #title>
                {{ $t("Users") }}
            </template>
             <template #head(role_id)="data">
                <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                    {{ data.label }}
                    <template v-if="data.field.sortable">
                    <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                    <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                    </template>
                </div>
                <b-form-select :options="rolesList" v-model="role_id" value-field="id"
                    text-field="name" @change="search(role_id)">
                    <template #first>
                        <b-form-select-option :value="''" ></b-form-select-option>
                    </template>
                </b-form-select>
            </template>
            <template #head(email)="data">
                <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                    {{ data.label }}
                    <template v-if="data.field.sortable">
                    <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                    <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                    </template>
                </div>
                <b-form-select :options="usersList" v-model="email" value-field="email"
                               text-field="email" @change="searchEmail(email)">
                    <template #first>
                        <b-form-select-option :value="''" ></b-form-select-option>
                    </template>
                </b-form-select>
            </template>
            <template v-slot:cell(role_id)='row'>
                <span v-for="(item,index) in rolesList" :key="index">
                    <span v-if="row.item.role_id == item.id">{{ item.name }}</span>
                </span>
            </template>
            <template v-slot:cell(pipelines)='row'>
                  <div v-for="(item,index) in pipelineList" :key="index">
                    <div v-for="userPipeline in row.item.pipelines">
                      <div v-if="userPipeline.pipeline.id === item.id" style="margin-right: 8px">{{ userPipeline.pipeline.name }} - {{ userPipeline.role }}</div>
                    </div>
                  </div>
            </template>
            <template v-slot:cell(last_login_at)='row'>
                {{ row.item.last_login_at | formatTimestamp }}
            </template>
            <template #button>
                <b-button variant="info" class="mr-1" @click="openModalForCreate">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">{{ $t("Users") }}</span>
                </b-button>
            </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="user-modal"
      centered
      :title="$t(title)"
      v-model="isShow"
      size="lg"
      hide-footer
    >
        <b-card no-body>
            <b-card-body>
                <validation-observer ref="rules" v-slot="{ invalid }">
                    <b-row>
                        <b-col lg="12" v-if="mode === 'CREATE'">
                          <validation-provider :name="$t('Native User')" rules="required">
                            <b-form-group
                              :label="$t('Native User')"
                              label-for=""
                              slot-scope="{ valid, errors }"
                            >
                              <b-form-select v-model="info.user_id" value-field="id" text-field="fullName" :options="nativeUsersList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="12">
                            <validation-provider :name="$t('Role')" rules="required">
                                <b-form-group
                                :label="$t('Role')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                                >
                                    <b-form-select v-model="info.role_id" value-field="id" text-field="name" :options="rolesList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12">
                            <validation-provider :name="$t('Default Currency')" rules="required">
                                <b-form-group
                                :label="$t('Default Currency')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select v-model="info.default_currency" :options="currencyList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12">
                            <validation-provider :name="$t('Default Language')" rules="required">
                                <b-form-group
                                :label="$t('Default Language')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                                >
                                    <b-form-select v-model="info.default_language" :options="languageList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col lg="12">
                          {{$t("To add user to pipelines, go to 'Pipeline' section.")}}
                        </b-col>
                    </b-row>
                <div>
                    <div class="w-100 d-flex justify-content-end">
                        <b-button
                            variant="info"
                            class="btn mr-2"
                            @click="isShow=false;$refs.advanceTable.loadList()"
                        >
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button
                            variant="primary"
                            class="btn"
                            @click="save"
                            :disabled="invalid"
                        >
                            {{ $t('Save') }}
                        </b-button>
                    </div>
                </div>
                </validation-observer>
            </b-card-body>
        </b-card>
    </b-modal>
</div>
</template>
<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import vSelect from 'vue-select'
import countries from "countries-list";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components:{vSelect,AdvanceTable,ToastificationContent},
    data(){
        return{
            window: {
                width: 0,
                height: 0
            },
            title:'Add User',
            isShow:false,
            info:{},
            columns:[
                { key: 'name', modelName:'nativeUser.fullName', label: 'User Name', filtertype: "input", sortable: false},
                { key: 'email', modelName:'nativeUser.email', label: 'Email', sortable: false},
                { key: 'role_id', modelName:'roleId', label: 'Role', sortable: false},
                { key: 'pipelines', modelName:'pipelines', label: 'Pipelines', sortable: false},
                { key: 'last_login_at', modelName:'last_login_at', label: 'Last Login', sortable: false},
            ],
            role_id:'',
            sortBy: "",
            sortDesc: true,
            rolesList:[],
            usersList:[],
            pipelineList: [],
            nativeUsersList: [],
            currencyList:[],
            languageList:[],
            email:''
        }
    },
    mounted(){
        this.getMasterData()
        this.getNativeUsers()
        this.getPipelines();
        this.getUsers()
        this.getRoles()
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods:{
        getFormat(t) {
            var date = new Date(+new Date(t) + 8 * 3600 * 1000)
            var seperator = "-"
            var year = date.getFullYear();
            var month = date.getMonth()+1;
            var strDate = date.getDate();
            var hours = date.getHours();
            var mm = date.getMinutes();
            var s = date.getSeconds();
            if(month >= 1 && month <= 9){
                month = "0" + month
            }
            if(strDate >= 0 && strDate <= 9){
                strDate = "0" + strDate
            }
            var currentDate = year + seperator + month + seperator + strDate + ' '+hours+':'+mm+':'+s
            return currentDate
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        clearData(){
            this.email = ''
            this.role_id= ''
        },
        search(val){
            this.$refs.advanceTable.loadList({'roleId':val});
        },
        searchEmail(val){
            this.$refs.advanceTable.loadList({'nativeUser.email':val});
        },
        openModalForCreate(){
            Object.keys(this.info).forEach(key => (this.info[key] = ''));
            this.$refs.advanceTable.loadList()
            this.isShow = true
            this.mode = 'CREATE';
            this.title = 'Add User'
        },
        openModalForEdit(item){
            this.title = 'Update User - ' + item.name
            this.isShow = true
            this.mode = 'EDIT';
            this.info = item
            this.username = item.name
        },
        async save(){
            if (this.mode === 'CREATE') {
                try {
                    const response = await fetcher(
                        apis.crmPutPostUser,
                        FetchMethod.POST,
                        this.info
                    )
                    if (response.error) {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: response.error.detail,
                                icon: 'XIcon',
                                variant: 'danger',
                                },
                        })
                        return false;
                    }
                    this.isShow = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Add User Success',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.$refs.advanceTable.loadList();
                }catch (e) {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: e.detail,
                            icon: 'XIcon',
                            variant: 'danger',
                            },
                    })
                }
            } else if (this.mode === 'EDIT') {
                try {
                    const response = await fetcher(
                        apis.crmPutUpdateUser+'/'+this.info.id,
                        FetchMethod.PUT,
                        this.info
                    )
                    if (response.error) {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: response.error.detail,
                                icon: 'XIcon',
                                variant: 'danger',
                                },
                        })
                        return false;
                    }
                    this.isShow = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Update User Success',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.$refs.advanceTable.loadList();
                } catch (e) {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: e.detail,
                            icon: 'XIcon',
                            variant: 'danger',
                            },
                    })
                }
            }
        },
        async getRoles(){
            try {
                const response = await fetcher(
                    apis.crmGetRoles,
                    FetchMethod.GET,
                )
                this.rolesList = response.data
            } catch (e) {
                console.log(e);
            }
        },
        async getMasterData(){
            try {
              const response = await fetcher(
                apis.crmGetMasterData,
                FetchMethod.GET,
                {pageSize: 1000000}
              );

              response.data.find(item => {
                if (item.type === "CURRENCY") {
                  this.currencyList.push(item.value.code);
                } else if (item.type === "LANGUAGE") {
                  this.languageList.push(item.value.name);
                } else if (item.type === "Language") {
                  // Deprecated usage
                  let data = item.value;
                  for (let a in data) {
                    this.languageList.push(data[a]);
                  }
                }
              });

              console.log(this.languageList);
            } catch (e) {
                console.log(e);
            }
        },
        async getUsers(){
            try {
                const response = await fetcher(
                    apis.crmGetUsers,
                    FetchMethod.GET,
                )
                this.usersList = response.data
            } catch (e) {
                console.log(e);
            }
        },

        async getNativeUsers(){
          try {
            const response = await fetcher(
              apis.getUsers,
              FetchMethod.GET,
            )
            this.nativeUsersList = response.data
          } catch (e) {
            console.log(e);
          }
        },

        async getPipelines() {
          try {
            const response = await fetcher(apis.crmGetPipeline, FetchMethod.GET);
            this.pipelineList = response.data;
          } catch (e) {
            console.log(e);
          }
        },
    }
}
</script>